.previewLoader {
  position: absolute;
  opacity: 0;
}

.iphone-wrapper {
  width: 300px;
  position: relative;
  cursor: pointer;

  .iphone-image {
    width: 100%;
  }

  .notification {
    position: absolute;
    top: 0;
    margin-top: 230px;
    left: 0;
    right: 0;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.6s ease-in-out;
    animation: slidein 0.6s ease-in-out;

    @keyframes slidein {
      from {
        opacity: 0.5;
        margin-top: 420px;
      }

      to {
        opacity: 1;
        margin-top: 230px;
      }
    }

    .icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;

      svg {
        color: #ccc;
      }
    }

    .wrapper {
      display: flex;
      flex: 1 1 auto;
      background: rgba(33, 33, 33, 0.75);
      backdrop-filter: blur(3px);
      border-radius: 10px;
      padding: 7px;
      color: #fff;
      position: relative;
      user-select: none;
      overflow: hidden;

      .now {
        position: absolute;
        font-size: 11px;
        right: 0;
        margin: 0 16px;
      }

      .title {
        font-weight: 500;
        font-size: 13px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100px;
      }

      .text {
        font-size: 13px;
        word-break: break-all;
        max-height: 50px;
      }

      img {
        border-radius: 14px;
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
}

//old
.examples {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;

      svg {
        color: #ccc;
      }
    }

    .ios-notification {
      display: flex;
      flex: 1 1 auto;
      background: #212121;
      border-radius: 14px;
      padding: 10px;
      color: #fff;
      position: relative;
      user-select: none;
      overflow: hidden;
      max-width: 320px;

      .now {
        position: absolute;
        font-size: 12px;
        color: #888888;
        right: 0;
        margin: -2px 16px 0 16px;
      }

      .title {
        font-weight: 500;
      }

      img {
        border-radius: 14px;
        width: 50px;
        height: 50px;
        margin-right: 14px;
      }
    }

    .android-notification {
      background: #d4d5d7;
      border-radius: 14px;
      padding: 10px 16px;
      overflow: hidden;
      flex: 1 1 auto;
      user-select: none;
      max-width: 320px;

      .now {
        margin-bottom: 4px;
      }

      .title {
        font-weight: 500;
      }

      .message {
        color: #585252;
      }

      img {
        width: 45px;
        height: 45px;
      }
    }

    .web-notification {
      background: #e5ecf0;
      border-radius: 5px;
      flex: 1 1 auto;
      font-size: 16px;
      padding: 10px;
      max-width: 320px;
      user-select: none;

      .date {
        font-size: 12px;
        color: #777;
      }

      .consultant {
        font-weight: 600;
      }
    }
  }
}
