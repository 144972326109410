.create-notification-page {
  .main-form {
    margin-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
  }

  .push-filters-wrapper {
    .selected-users-label {
      margin-top: 16px;
    }

    .alert-count {
      background: #fff;
    }

    .progress-wrapper {
      height: 4px;
    }

    .clear-dates-button {
      margin: 0 3px;
    }
  }
}
