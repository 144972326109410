.reviews-container {
  overflow-y: scroll;
  .review-card {
    margin-bottom: 12px;

    .action-button {
      margin-bottom: 14px;
    }

    .delete-label {
      color: rgba(224, 38, 38, 0.84);
      text-align: center;
      cursor: pointer;
    }

    .product-label {
      margin-bottom: 6px;

      a {
        color: #1778d9;
        font-size: 16px;
        text-decoration: none;
      }
    }

    .phone-label {
      margin-bottom: 6px;

      a {
        color: #1778d9;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
}
